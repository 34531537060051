import React from "react";
import "./CourseCard.css";

const CourseCard = ({ title, description, image, formLink }) => {
    return (
        <div className="course-card">
            <img src={image} alt={title} className="course-image" />
            <div className="course-content">
                <h3 className="course-title">{title}</h3>
                <p className="course-description">{description}</p>
                <a
                    href={formLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="enroll-button"
                >
                    Enroll Now
                </a>
            </div>
        </div>
    );
};

export default CourseCard;
