import React from "react";

import './Internship.css';
import CourseCard from "../../../pages/CourseCard/CourseCard";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import fullstack from "../../../assets/images/fullstack.png";
import aiml from "../../../assets/images/aindml.webp";
import network from "../../../assets/images/netwrking.jpg";

// Course data
const courses = [
    {
        title: "Full Stack Web Development",
        description: "Learn front-end, back-end, and database technologies to build modern web applications.",
        image: fullstack,
    },
    {
        title: "Artificial Intelligence & Machine Learning",
        description: "Master the fundamentals of AI and build ML models for real-world applications.",
        image: aiml,
    },
    {
        title: "Networking Essentials",
        description: "Understand network configurations, security, and protocols to manage IT systems.",
        image: network,
    },
];

// Shared Google Form link
const formLink = "https://forms.gle/Y4D739UUQvFwePy18"; // Replace with your actual form link

const Internship = () => {
    const breadcrumbs = [
        { label: "Home", link: "home" },
        { label: "Internship", link: "Internship" },
    ];

    return (
        <>
            {/* Breadcrumb Navigation */}
            <Breadcrumb pageTitle="Internship" breadcrumbs={breadcrumbs} />

            {/* Course List */}
            <div className="course-list container">
                {courses.map((course, index) => (
                    <CourseCard
                        key={index}
                        title={course.title}
                        description={course.description}
                        image={course.image}
                        formLink={formLink} // Pass the shared form link
                    />
                ))}
            </div>
        </>
    );
};

export default Internship;
