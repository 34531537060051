import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Review from '../pages/Review/Review';
import ContactUs from '../components/MainPages/ContactUs/ContactUs';
import About from '../components/MainPages/About/About';
import Service from '../components/MainPages/Service/Service';
import Internship from '../components/MainPages/Internship/Internship';

function AppRoutes() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/review" element={<Review />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/aboutus" element={<About />} />
                <Route path="/service" element={<Service />} />
                <Route path="/internship" element={<Internship />} />

            </Routes>
        </Router>
    );
}

export default AppRoutes;
