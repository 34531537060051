import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactUs.css';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs
            .send(
                'service_ct0e5ru', // Replace with your EmailJS service ID
                'template_1vj6j19', // Replace with your EmailJS template ID
                {
                    name: formData.name,
                    phone: formData.phone,
                    email: formData.email,
                    message: formData.message,
                },
                'YeAc68eoe1xo7UNYx' // Replace with your EmailJS user ID
            )
            .then((response) => {
                alert('Message sent successfully!');
                setFormData({ name: '', phone: '', email: '', message: '' });
            })
            .catch((error) => {
                alert('Failed to send message. Please try again.');
            });
    };

    const breadcrumbs = [
        { label: 'Home', link: 'home' },
        { label: 'Contact Us', link: 'contact-us' },
    ];

    return (
        <>
            <Breadcrumb pageTitle="Contact Us" breadcrumbs={breadcrumbs} />
            <div className="contact-container container">
                <div className="contact-info">
                    <h2 className="contact-heading">Let's build an awesome project together</h2>
                    <p className="contact-description">
                        We’re here to help bring your ideas to life. Whether you need a website, mobile app, or custom software solution, our team of experts is ready to collaborate and deliver exceptional results tailored to your needs.
                    </p>

                    <div className="contact-details">
                        <div className="contact-item">
                            <div className="icon-box">
                                <span className="icon">&#128205;</span>
                            </div>
                            <div>
                                <h4>Address</h4>
                                <p>AIC Incubation center<br />NITTE, 574110</p>
                            </div>
                        </div>

                        <div className="contact-item">
                            <div className="icon-box">
                                <span className="icon">&#9993;</span>
                            </div>
                            <div>
                                <h4>Contact</h4>
                                <p>neuraltechitsolution@gmail.com<br />+91 9108316049</p>
                            </div>
                        </div>
                    </div>

                    <p className="office-hours">Office Hours: Mon – Sat: 9:30 AM – 5:30 PM</p>
                </div>

                <div className="contact-form">
                    <h3>Fill The Contact Form</h3>
                    <p>Feel free to contact with us, we don’t spam your email</p>

                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="text"
                                name="name"
                                placeholder="Your name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <input
                                type="text"
                                name="phone"
                                placeholder="Phone number"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                name="email"
                                placeholder="Email address"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <textarea
                                name="message"
                                placeholder="Write your message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            ></textarea>
                        </div>
                        <button className="sendbtn" type="submit">Send Message</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
